import React from 'react';

import PageLayout from '../views/Layout/Page';

export default function CookiesPage() {
  return (
    <PageLayout maySkipBlocker pageview="cookies">
      <main
        className="container main-container container--top container--bottom"
        dangerouslySetInnerHTML={{
          __html: `<div id="ot-sdk-cookie-policy" />`,
        }}
      />
    </PageLayout>
  );
}
